<template>
  <div>
    <div
      class="root"
      :style="rootStyle"
      @mousedown="ballMouseDown"
      @mousemove="ballMouseMove"
      @mouseup="ballMouseUp"
      @mouseleave="ballMouseLeave"
    >
      <div ref="line" class="line" :style="lineStyle">
        <img
          src="../assets/home-10.png"
          alt=""
          ref="ball"
          class="ball"
          :style="ballStyle"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: Number,
    width: String,
    height: String,
    lineBackground: {
      type: String,
      default: "lightgray",
    },
    lineWidth: {
      type: Number,
    },
    lineHeight: {
      type: Number,
    },
    ballBackground: {
      type: String,
      default: "yellow",
    },
    ballSize: {
      type: Number,
      default: 40,
    },
  },
  data() {
    return {
      isdown: false, // 记录鼠标是否被按下。
    };
  },

  created() {},

  mounted() {},

  methods: {
    ballMouseDown(e) {
      this.isdown = true;
      this.ballMove(e);
    },
    ballMouseMove(e) {
      if (this.isdown) {
        this.ballMove(e);
      }
    },
    ballMouseUp() {
      this.isdown = false;
      // console.log("鼠标松开");
    },
    ballMouseLeave() {
      if (this.isdown) {
        this.isdown = false;
        // console.log("鼠标离开组件");
      }
    },
    ballMove(e) {
      // console.log('鼠标',e.clientX)
      // console.log('距离',this.$refs.line.offsetLeft)
      //e.clientX 触发时鼠标指针相对于浏览器页面（或客户区）的水平坐标
      // offsetLeft为元素边框外侧到父元素边框内侧的距离
      let distance = e.clientX - this.$refs.line.offsetLeft;
      if (distance < 0) {
        distance = 0;
      } else if (distance > this.lineWidth) {
        distance = this.lineWidth;
      }
      // 通过计算属性的方式改变小球left
      this.$emit("input", (distance / this.lineWidth).toFixed(2));
      // console.log((distance / this.lineWidth).toFixed(2))
    },
  },
  computed: {
    rootStyle() {
      return {
        width: this.width + "px",
        height: this.height + "px",
      };
    },
    lineStyle() {
      return {
        width: this.lineWidth + "px",
        height: this.lineHeight + "px",
      };
    },
    ballStyle() {
      let left = 0;
      if (this.value > 1) {
        left = 1 * this.lineWidth;
      } else if (this.value < 0) {
        left = 0 * this.lineWidth;
      } else {
        left = this.value * (this.lineWidth - 38);
      }
      return {
        left: left + "px",
      };
    },
  },
};
</script>
<style>
* {
  box-sizing: border-box;
}
.root {
  background-color: #FF9D78;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.line {
  position: relative;
}
.ball {
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
img {
  width: 38px;
  height: 25px;
}
</style>
